.wysiwyg {
    p {
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    b, strong {
        font-weight: 500;
    }

    i, em {
        font-style: italic;
    }

    a {
        @apply link;
        color: var(--theme-fg-alt);
    }

    h2 {
        @apply text-h4 text-theme-fg-alt;
        margin: 1.75em 0 0.8em;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    h3 {
        @apply text-h5;
        margin: 1.5em 0 0.8em;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 1.5em;
        margin-bottom: calc((22/16) * 1em);

        li {
            position: relative;

            &::before {
                content: "•";
                position: absolute;
                left: -1em;
                //position: relative;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 1.5em;
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    li {
        display: list-item;
        margin-bottom: 0.5em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
